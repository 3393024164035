import { PureComponent } from 'react'

import { get, has } from 'dot-prop'
import Firebase from 'firebase'
import hoistNonReactStatic from 'hoist-non-react-statics'
import isEmptyObject from 'is-empty-object'

import { FIREBASE } from '@helpers/constants'
import errorHandler from 'helpers/error-handler'

const FB_POLLING_INTERVALS = 'eazeWeb.pollingIntervals'

export default function withFirebasePollingIntervals(WrappedComponent) {
  class withFirebasePollingIntervalsComponent extends PureComponent {
    state = {
      pollingIntervals: {}
    }

    componentDidMount() {
      this.firebaseListener()
    }

    firebaseListener = () => {
      const firebase = new Firebase(FIREBASE)

      firebase.child('settings').on('value', (snapshot) => {
        const db = snapshot.val()
        const remoteState = get(db, FB_POLLING_INTERVALS)

        // Firebase has their access methods but I don't have time to test their behaviors, or if FB gives us empty objects. Firedrill.
        if (!has(db, FB_POLLING_INTERVALS) || isEmptyObject(remoteState)) {
          errorHandler(new Error('Failed to retrieve Firebase polling intervals'))
        }

        const current = this.state.pollingIntervals
        if (remoteState !== current) {
          this.setState({ pollingIntervals: remoteState })
        }
      })
    }

    render() {
      return <WrappedComponent {...this.props} pollingIntervals={this.state.pollingIntervals} />
    }
  }

  return hoistNonReactStatic(withFirebasePollingIntervalsComponent, WrappedComponent)
}
