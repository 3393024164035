import { CSSProperties, ReactNode } from 'react'

import styled from '@emotion/styled'

interface SubtextComponentProps {
  children?: ReactNode | string
  componentStyle?: CSSProperties
  className?: string
  color?: string
}

const Subtext = styled.p<SubtextComponentProps>`
  margin: 0;
  color: ${(p) => p.color || '#373838'};
`

const SubtextComponent = ({ children, componentStyle = {}, className = '', color = '' }: SubtextComponentProps) => (
  <Subtext className={className} style={componentStyle} color={color}>
    {children}
  </Subtext>
)

export default SubtextComponent
