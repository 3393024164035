import { ReactNode } from 'react'

import styled from '@emotion/styled'

const Text = styled.p<{ color?: string }>`
  color: ${(props) => props.color || props.theme.colors.text};
  font-size: ${(props) => props.theme.typography.size.desktop.body3}px;
  font-family: ${(props) => props.theme.typography.font.sim};
  margin: 0;
`

type TextProps = {
  children: ReactNode
  componentStyle?: Record<string, unknown>
  className?: string
  color?: string
  e2eId?: string
}

const TextComponent = ({ children, componentStyle = {}, className = '', color = '', e2eId }: TextProps) => (
  <Text className={className} style={componentStyle} color={color} data-e2eid={e2eId || null}>
    {children}
  </Text>
)

export default TextComponent
