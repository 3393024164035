/**
 * this file is used for firing events to sift science with a generated sessionId
 */

import window from 'global/window'
import random from 'random-number'

import { SIFT } from '@helpers/constants'

// we don't have a solid concept of session ids, and making something complete is too involved.
// for now: assign a session id to each instance of the app. a page refresh will reset the session id.
const sessionId = Date.now() + random({ min: 0, max: Number.MAX_SAFE_INTEGER, integer: true })

export default function siftEvent(state) {
  // we can do this here because this is the class that created Store in the first place. Don' be tryin this at home
  let userId = state.user.userId
  const sift = window._sift

  if (userId === '' || !userId) {
    userId = null
  }

  if (sift && sift.push) {
    sift.push(['_setAccount', SIFT])
    sift.push(['_setUserId', userId])
    sift.push(['_setSessionId', sessionId])
    sift.push(['_trackPageview'])
  } else {
    console.warn('Could not find Sift Science client in global scope')
  }
}
