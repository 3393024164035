import { ReactNode } from 'react'

import styled from '@emotion/styled'

import { breakpoint } from '@/theme'

const SectionTitle = styled.h2<{ color?: string }>`
  margin: 0;
  font-family: ${(props) => props.theme.typography.font.npBold};
  color: ${(props) => props.color || props.theme.colors.text};
  font-size: ${(props) => props.theme.typography.size.mobile.title2}px;
  line-height: 1;

  @media (min-width: ${breakpoint.min.md}px) {
    font-size: ${(props) => props.theme.typography.size.desktop.title3}px;
  }
`

type SectionTitleProps = {
  children: ReactNode
  componentStyle?: Record<string, unknown>
  className?: string
  color?: string
  e2eId?: string
}

const SectionTitleComponent = ({ children, componentStyle = {}, className, color, e2eId }: SectionTitleProps) => (
  <SectionTitle data-e2eid={e2eId} className={className} style={componentStyle} color={color}>
    {children}
  </SectionTitle>
)

export default SectionTitleComponent
