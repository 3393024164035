import { ReactNode } from 'react'

import styled from '@emotion/styled'

const Title = styled.h1<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : props.theme.colors.text)};
  font-size: ${(props) => props.theme.typography.size.desktop.title3}px;
  line-height: 1;
  margin: 0;
  font-weight: 800;
  font-family: ${(props) => props.theme.typography.font.npBold};
`

type TitleProps = {
  children: ReactNode
  componentStyle?: Record<string, unknown>
  className?: string
  color?: string
  e2eId?: string
}

const TitleComponent = ({ children, componentStyle = {}, className, color, e2eId }: TitleProps) => (
  <Title className={className} style={componentStyle} color={color} data-e2eid={e2eId}>
    {children}
  </Title>
)

export default TitleComponent
