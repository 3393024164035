// this should only be used in top level /pages/**/container!
import { Fragment, PureComponent } from 'react'

import load from '@eaze/load-script-global'
import pole from '@eaze/pole'
import Visibility from 'document-visibility'
import window from 'global/window'
import Router from 'next/router'

import HeadTag from 'components/head'
import siftEvent from 'helpers/sift'
import withFirebasePollingIntervals from 'hocs/with-firebase-polling-intervals'
import { setVersionMismatch } from 'redux/app/actions'
import { rehydrateCookies } from 'redux/cookies/actions'

import { object, string } from 'prop-types'

const visibility = Visibility()
const POLL_FREQUENCY = 60 * 1000
const noop = () => {}

export default function withPage(WrappedComponent, opts = { head: true }) {
  class withPageHoc extends PureComponent {
    static propTypes = {
      pollingIntervals: object,
      router: object,
      promoCode: string
    }

    static defaultProps = {
      pollingIntervals: {},
      router: {},
      promoCode: ''
    }

    state = {
      dispatch: null,
      version: null,
      versionPoll: null,
      pollFrequency: POLL_FREQUENCY
    }

    unlistenVisibility = visibility.onChange((visible) => (visible ? this.setVersionPoll() : this.cancelPolling()))

    async deregisterServiceWorker() {
      if (window.navigator && navigator.serviceWorker) {
        const registrations = await navigator.serviceWorker.getRegistrations()
        for (const registration of registrations) {
          registration.unregister()
        }
      }
    }

    componentWillUnmount() {
      if (this.unlistenVisibility) this.unlistenVisibility()

      this.cancelPolling()
    }

    componentDidMount() {
      const reduxStore = window.__NEXT_REDUX_WRAPPER_STORE__
      const state = reduxStore.getState()
      const dispatch = reduxStore.dispatch

      rehydrateCookies(dispatch)

      if (!window._sift) {
        load({ url: 'https://cdn.siftscience.com/s.js', global: '_sift' }, (err) => err && console.warn(err))
      }

      Router.events.on('routeChangeStart', () => {
        siftEvent(state)
      })

      this.setState({ dispatch }, this.handleInitialSetState)
      this.deregisterServiceWorker()
    }

    handleInitialSetState = () => {
      // TODO: hook this up to a firebase variable
      this.setVersionPoll()
    }

    componentDidUpdate(prevProps) {
      this.updatePolling(prevProps)
    }

    fetchVersion = async (callback) => {
      const { version } = this.state
      // we don't care about this during development, so lets return early
      if (!window.location || !window.location.origin) return callback(null)

      // let's hit out root/version and see what version we're on
      try {
        const res = await fetch(`${window.location.origin}/status`)
        const body = res.headers.get('content-type').includes('json') ? await res.json() : await res.text()
        const serverVersion = body.version

        if (!res.ok) {
          console.info(`FetchVersion request status ${res.status} not OK`, body)
          throw new Error(`FetchVersion request status ${res.status} not OK`)
        }
        //  we don't have a version yet? let's save the commit hash in state.
        if (version === null && serverVersion) {
          this.setState({ version: serverVersion })
        } else if (version !== null && version !== serverVersion) {
          // we have this.state.version and serverVersion isn't equal to it.. let's refresh the app
          // to ensure the users are on the latest version
          this.state.dispatch(setVersionMismatch())
        }

        return callback(null, res)
      } catch (err) {
        return callback(err, null)
      }
    }

    setVersionPoll = () => {
      const { pollingIntervals } = this.props
      const interval = (pollingIntervals && pollingIntervals.appVersion) || POLL_FREQUENCY
      const versionPoll = pole({ interval }, (callback) => this.fetchVersion(callback))

      this.setState({ versionPoll, pollFrequency: interval }, () =>
        console.info(`set appVersion poll - FB: ${pollingIntervals.appVersion}, Fallback: ${POLL_FREQUENCY}`)
      )
    }

    cancelPolling = (cb = noop) => {
      const { versionPoll } = this.state
      versionPoll && versionPoll.cancel()
      this.setState({ versionPoll: null }, cb)
    }

    updatePolling = (prevProps) => {
      const { pollingIntervals } = this.props
      const prevIntervals = prevProps.pollingIntervals
      if (
        pollingIntervals.appVersion !== prevIntervals.appVersion &&
        pollingIntervals.appVersion !== this.state.pollFrequency
      ) {
        this.cancelPolling(this.setVersionPoll)
      }
    }

    render() {
      const { router, promoCode } = this.props

      return (
        <Fragment>
          {opts.head && <HeadTag pathname={router.pathname} promoCode={promoCode} path={router.pathname} />}
          <WrappedComponent {...this.props} />
        </Fragment>
      )
    }
  }
  return withFirebasePollingIntervals(withPageHoc)
}
