import { ReactNode } from 'react'

import styled from '@emotion/styled'

interface LabelComponentProps {
  children?: ReactNode | string
  className?: string
  content?: string
  [key: string]: any // for otherProps rest object
}

const Label = styled.label<LabelComponentProps>`
  font-size: ${({ theme }) => theme.typography.size.desktop.body2}px;
  font-weight: 400;
  text-transform: uppercase;
`

const LabelComponent = ({ children = '', className = '', content = '', ...otherProps }: LabelComponentProps) => (
  <Label className={className} {...otherProps}>
    {children}
    {content}
  </Label>
)

export default LabelComponent
